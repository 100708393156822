import logo from "./logo";

const ROLES = {
    FREELANCER: "freelancer",
    HR: "hr",
    CLIENT: "client",
    EMPLOYEE: "employee",
};

export const RESUME_TYPE = {
    CUSTOM: "CUSTOM",
    FILE: "FILE",
};

export const STATUSES = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    OPEN: "OPEN",
    IN_PROGRESS: "IN_PROGRESS",
    DONE: "DONE",
    CLOSED: "CLOSED",
};

export const statusColorMap = {
    ACCEPTED: "bg-[#D5DFFF] text-blue-500",
    CLOSED: "bg-[#FFD5D5] text-[#E94040]",
    OPEN: "bg-[#D5DFFF] text-blue-500",
    IN_PROGRESS: "bg-[#EAFFF9] text-[#00A884] border border-[#00A884]",
};

const LANGUAGES = [
    { name: "English", value: "English" },
    { name: "Mandarin Chinese", value: "Mandarin Chinese" },
    { name: "Hindi", value: "Hindi" },
    { name: "Spanish", value: "Spanish" },
    { name: "French", value: "French" },
    { name: "Arabic", value: "Arabic" },
    { name: "Bengali", value: "Bengali" },
    { name: "Portuguese", value: "Portuguese" },
    { name: "Russian", value: "Russian" },
    { name: "Japanese", value: "Japanese" },
    { name: "Punjabi", value: "Punjabi" },
    { name: "German", value: "German" },
    { name: "Korean", value: "Korean" },
    { name: "Vietnamese", value: "Vietnamese" },
    { name: "Telugu", value: "Telugu" },
    { name: "Turkish", value: "Turkish" },
    { name: "Tamil", value: "Tamil" },
    { name: "Marathi", value: "Marathi" },
    { name: "Italian", value: "Italian" },
    { name: "Thai", value: "Thai" },
    { name: "Gujarati", value: "Gujarati" },
    { name: "Urdu", value: "Urdu" },
    { name: "Indonesian", value: "Indonesian" },
    { name: "Malay", value: "Malay" },
    { name: "Persian", value: "Persian" },
    { name: "Polish", value: "Polish" },
    { name: "Dutch", value: "Dutch" },
    { name: "Swahili", value: "Swahili" },
];

const LanguageProficiency = [
    {
        name: "Basic",
        value: "Basic",
    },
    {
        name: "Conversational",
        value: "Conversational",
    },
    {
        name: "Fluent",
        value: "Fluent",
    },
    {
        name: "Native or Bilingual",
        value: "Native or Bilingual",
    },
];

const ACTION_REQUIRED_VALUE_TYPE = [
    { name: "Percentage", value: "PERCENTAGE" },
    { name: "Coins", value: "COINS" },
];

const Degrees = [
    { name: "Bachelor of Arts", value: "Bachelor of Arts" },
    { name: "Bachelor of Science", value: "Bachelor of Science" },
    { name: "Bachelor of Commerce", value: "Bachelor of Commerce" },
    { name: "Bachelor of Engineering", value: "Bachelor of Engineering" },
    { name: "Bachelor of Technology", value: "Bachelor of Technology" },
    {
        name: "Bachelor of Business Administration",
        value: "Bachelor of Business Administration",
    },
    {
        name: "Bachelor of Medicine, Bachelor of Surgery",
        value: "Bachelor of Medicine, Bachelor of Surgery",
    },
    { name: "Master of Arts", value: "Master of Arts" },
    { name: "Master of Science", value: "Master of Science" },
    { name: "Master of Commerce", value: "Master of Commerce" },
    { name: "Master of Engineering", value: "Master of Engineering" },
    { name: "Master of Technology", value: "Master of Technology" },
    {
        name: "Master of Business Administration",
        value: "Master of Business Administration",
    },
    { name: "Doctor of Philosophy", value: "Doctor of Philosophy" },
    { name: "Doctor of Medicine", value: "Doctor of Medicine" },
];

const Skills = [
    { name: "Drawing", value: "Drawing" },
    { name: "Painting", value: "Painting" },
    { name: "Sketching", value: "Sketching" },
    { name: "Illustration", value: "Illustration" },
    { name: "Digital Art", value: "Digital Art" },
    { name: "Graphic Design", value: "Graphic Design" },
    { name: "3D Modeling", value: "3D Modeling" },
    { name: "Sculpting", value: "Sculpting" },
    { name: "Calligraphy", value: "Calligraphy" },
    { name: "Animation", value: "Animation" },
    { name: "2D Animation", value: "2D Animation" },
    { name: "3D Animation", value: "3D Animation" },
    { name: "Storyboarding", value: "Storyboarding" },
    { name: "Typography", value: "Typography" },
    { name: "Tattoo Design", value: "Tattoo Design" },
    { name: "Photography", value: "Photography" },
    { name: "Photo Editing", value: "Photo Editing" },
    { name: "Video Editing", value: "Video Editing" },
    { name: "Film Production", value: "Film Production" },
    { name: "Concept Art", value: "Concept Art" },
    { name: "Character Design", value: "Character Design" },
    { name: "Fashion Illustration", value: "Fashion Illustration" },
    { name: "Mural Art", value: "Mural Art" },
    { name: "Printmaking", value: "Printmaking" },
    { name: "Fine Arts", value: "Fine Arts" },
    { name: "Caricature", value: "Caricature" },
    { name: "Pixel Art", value: "Pixel Art" },
    { name: "Game Art", value: "Game Art" },
    { name: "Textile Design", value: "Textile Design" },
    { name: "Set Design", value: "Set Design" },
    { name: "Interior Design", value: "Interior Design" },
    {
        name: "Architectural Visualization",
        value: "Architectural Visualization",
    },
    { name: "UI/UX Design", value: "UI/UX Design" },
    { name: "Motion Graphics", value: "Motion Graphics" },
    { name: "Illustrated Storytelling", value: "Illustrated Storytelling" },
    { name: "Logo Design", value: "Logo Design" },
    { name: "Packaging Design", value: "Packaging Design" },
    { name: "Ceramic Art", value: "Ceramic Art" },
    { name: "Glass Art", value: "Glass Art" },
    { name: "Jewelry Design", value: "Jewelry Design" },
    { name: "Prop Making", value: "Prop Making" },
    { name: "Stage Design", value: "Stage Design" },
    { name: "Street Art", value: "Street Art" },
    { name: "Stencil Art", value: "Stencil Art" },
    { name: "Portrait Painting", value: "Portrait Painting" },
    { name: "Landscape Painting", value: "Landscape Painting" },
    { name: "Miniature Art", value: "Miniature Art" },
    { name: "Hyperrealism", value: "Hyperrealism" },
    { name: "Comic Art", value: "Comic Art" },
    { name: "Book Illustration", value: "Book Illustration" },
    { name: "Augmented Reality Art", value: "Augmented Reality Art" },
    { name: "Virtual Reality Art", value: "Virtual Reality Art" },
    { name: "AI-Generated Art", value: "AI-Generated Art" },
    { name: "NFT Art", value: "NFT Art" },
];

const Months = [
    { name: "January", value: "January" },
    { name: "February", value: "February" },
    { name: "March", value: "March" },
    { name: "April", value: "April" },
    { name: "May", value: "May" },
    { name: "June", value: "June" },
    { name: "July", value: "July" },
    { name: "August", value: "August" },
    { name: "September", value: "September" },
    { name: "October", value: "October" },
    { name: "November", value: "November" },
    { name: "December", value: "December" },
];

const PreferredWorkingHours = [
    { name: "Full-time (9 AM - 5 PM)", value: "Full-time (9 AM - 5 PM)" },
    { name: "Part-time (Morning)", value: "Part-time (Morning)" },
    { name: "Part-time (Afternoon)", value: "Part-time (Afternoon)" },
    { name: "Part-time (Evening)", value: "Part-time (Evening)" },
    { name: "Flexible Hours", value: "Flexible Hours" },
    { name: "Night Shift", value: "Night Shift" },
    { name: "Weekend Only", value: "Weekend Only" },
    { name: "Freelance/Project-Based", value: "Freelance/Project-Based" },
];

const TypeOfGigs = [
    { name: "Traditional Art", value: "Traditional Art" },
    { name: "Digital Art", value: "Digital Art" },
    { name: "Graphic Design", value: "Graphic Design" },
    { name: "3D Art & Modeling", value: "3D Art & Modeling" },
    {
        name: "Animation & Motion Graphics",
        value: "Animation & Motion Graphics",
    },
    { name: "Illustration & Concept Art", value: "Illustration & Concept Art" },
    { name: "Photography & Videography", value: "Photography & Videography" },
    { name: "Typography & Calligraphy", value: "Typography & Calligraphy" },
    { name: "Fashion & Textile Design", value: "Fashion & Textile Design" },
    { name: "Sculpture & Installations", value: "Sculpture & Installations" },
    { name: "Fine Arts", value: "Fine Arts" },
    { name: "Game Art & Design", value: "Game Art & Design" },
    { name: "UI/UX & Web Design", value: "UI/UX & Web Design" },
    { name: "Printmaking & Engraving", value: "Printmaking & Engraving" },
    { name: "Film & Cinematic Arts", value: "Film & Cinematic Arts" },
    { name: "Set & Stage Design", value: "Set & Stage Design" },
    { name: "Tattoo Art", value: "Tattoo Art" },
    { name: "Jewelry & Accessory Design", value: "Jewelry & Accessory Design" },
    { name: "Street & Urban Art", value: "Street & Urban Art" },
    { name: "AI-Generated & NFT Art", value: "AI-Generated & NFT Art" },
];

const ExperienceLevel = [
    { name: "No Experience", value: "No Experience" },
    { name: "Entry Level", value: "Entry Level" },
    { name: "Junior Level", value: "Junior Level" },
    { name: "Mid Level", value: "Mid Level" },
    { name: "Senior Level", value: "Senior Level" },
    { name: "Lead Level", value: "Lead Level" },
    { name: "Specialist", value: "Specialist" },
    { name: "Mentor/Trainer", value: "Mentor/Trainer" },
    { name: "Director Level", value: "Director Level" },
    { name: "Other", value: "Other" },
];

const PreferredJobLocations = [
    { name: "Delhi", value: "Delhi" },
    { name: "Mumbai", value: "Mumbai" },
    { name: "Bangalore", value: "Bangalore" },
    { name: "Hyderabad", value: "Hyderabad" },
    { name: "Chennai", value: "Chennai" },
    { name: "Kolkata", value: "Kolkata" },
    { name: "Pune", value: "Pune" },
    { name: "Ahmedabad", value: "Ahmedabad" },
    { name: "Jaipur", value: "Jaipur" },
    { name: "Surat", value: "Surat" },
    { name: "Lucknow", value: "Lucknow" },
    { name: "Indore", value: "Indore" },
    { name: "Nagpur", value: "Nagpur" },
    { name: "Coimbatore", value: "Coimbatore" },
    { name: "Chandigarh", value: "Chandigarh" },
    { name: "Thiruvananthapuram", value: "Thiruvananthapuram" },
    { name: "Patna", value: "Patna" },
    { name: "Bhopal", value: "Bhopal" },
    { name: "Visakhapatnam", value: "Visakhapatnam" },
    { name: "Vadodara", value: "Vadodara" },
    { name: "Noida", value: "Noida" },
    { name: "Gurgaon", value: "Gurgaon" },
    { name: "Faridabad", value: "Faridabad" },
    { name: "Mysore", value: "Mysore" },
    { name: "Kochi", value: "Kochi" },
    { name: "Varanasi", value: "Varanasi" },
    { name: "Rajkot", value: "Rajkot" },
    { name: "Amritsar", value: "Amritsar" },
    { name: "Jodhpur", value: "Jodhpur" },
];

const Industry = [
    {
        name: "Information Technology (IT)",
        value: "Information Technology (IT)",
    },
    { name: "Healthcare", value: "Healthcare" },
    { name: "Finance", value: "Finance" },
    { name: "Education", value: "Education" },
    { name: "Construction", value: "Construction" },
    { name: "Manufacturing", value: "Manufacturing" },
    { name: "Retail", value: "Retail" },
    { name: "Telecommunications", value: "Telecommunications" },
    { name: "Hospitality", value: "Hospitality" },
    { name: "Media and Entertainment", value: "Media and Entertainment" },
    { name: "Real Estate", value: "Real Estate" },
    { name: "Automotive", value: "Automotive" },
    { name: "Aerospace", value: "Aerospace" },
    { name: "Agriculture", value: "Agriculture" },
    { name: "Legal Services", value: "Legal Services" },
    { name: "Logistics and Supply Chain", value: "Logistics and Supply Chain" },
    {
        name: "Government and Public Sector",
        value: "Government and Public Sector",
    },
    { name: "Nonprofit and NGO", value: "Nonprofit and NGO" },
    { name: "Energy and Utilities", value: "Energy and Utilities" },
    { name: "Pharmaceuticals", value: "Pharmaceuticals" },
    { name: "E-commerce", value: "E-commerce" },
    { name: "Consulting", value: "Consulting" },
    { name: "Food and Beverage", value: "Food and Beverage" },
    { name: "Sports and Recreation", value: "Sports and Recreation" },
    { name: "Technology", value: "Technology" },
    { name: "Cybersecurity", value: "Cybersecurity" },
    { name: "Fashion and Apparel", value: "Fashion and Apparel" },
    { name: "Human Resources", value: "Human Resources" },
    { name: "Marketing and Advertising", value: "Marketing and Advertising" },
];

const IndustrySize = [
    { name: "Small (1-50 employees)", value: "Small (1-50 employees)" },
    { name: "Medium (51-200 employees)", value: "Medium (51-200 employees)" },
    { name: "Large (201-500 employees)", value: "Large (201-500 employees)" },
    {
        name: "Very Large (500+ employees)",
        value: "Very Large (500+ employees)",
    },
    { name: "Startup", value: "Startup" },
    { name: "Enterprise", value: "Enterprise" },
];

const JobScope = [
    {
        name: "Large",
        value: "Large",
    },
    {
        name: "Medium",
        value: "Medium",
    },
    {
        name: "Small",
        value: "Small",
    },
];

const JobExperienceLevel = [
    {
        name: "Entry",
        value: "Entry",
    },
    {
        name: "Intermediate",
        value: "Intermediate",
    },
    {
        name: "Expert",
        value: "Expert",
    },
];

const Master = {
    Skills: "Skills",
    Certificates: "Certificates",
    Languages: "Languages",
    Roles: "Roles",
    Countries: "Countries",
    "Work Location Type": "Work Location Type",
    "Work Location": "Work Location",
};

const CreditActionTypes = {
    "Review Invite": "Review Invite",
    "Apply Opportunity": "Apply Opportunity",
};

const CREDIT_ACTION = {
    APPLY_JOB: "APPLY_JOB",
    POST_JOB: "POST_JOB",
    SHOW_CONTACT: "SHOW_CONTACT",
};

export {
    logo,
    ROLES,
    LANGUAGES,
    LanguageProficiency,
    Degrees,
    Skills,
    Months,
    PreferredWorkingHours,
    TypeOfGigs,
    ExperienceLevel,
    PreferredJobLocations,
    Industry,
    IndustrySize,
    JobScope,
    JobExperienceLevel,
    Master,
    CreditActionTypes,
    ACTION_REQUIRED_VALUE_TYPE,
    CREDIT_ACTION,
};
