import { ArrowLeft } from "iconsax-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import logos from "../../constant/logo";
import CommonButton from "./CommonButton";

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <div className="center-vertical h-screen">
            <img src={logos.NoPostFoundImage} alt="Not Found" className="w-96 pb-5" loading="lazy" />
            <h1 className="text-2xl font-semibold">404 - Page Not Found</h1>
            <p>
                The page you are looking for might have been removed, had its
                name changed, or is temporarily unavailable.
            </p>
            <p>
                Please go back to the previous page or try searching for
                something else.
            </p>

            <CommonButton
                isGradient={true}
                isRounded={true}
                handleFunction={() => navigate(-1)}
                title={"Go Back"}
                Icon={ArrowLeft}
                isWithIcon={true}
                buttonStyle={"!w-32 mt-5"}
            />
        </div>
    );
};

export default NotFound;
