import React from "react";
import useGlobalQuery from "../../../hooks/useGlobalQuery";
import { ROUTES } from "../../../Routes";
import { useSearchParams, useNavigate } from "react-router-dom";

const TrendingSearches = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const { queryData } = useGlobalQuery({
        url: ROUTES.FEATURE.TRENDING_SEARCH,
        methodType: "GET",
        data: {},
        queryKey: ["trending-search"],
    });

    const setSearchKey = (key) => {
        const newParams = new URLSearchParams(searchParams);
        newParams.set("search", key);
        navigate(`?${newParams.toString()}`);
    };

    return (
        <div className=" flex flex-col sm:flex-row text-xs gap-3 sm:gap-0 items-center sm:items-start">
            <h3 className="text-gray-600 sm:text-light-gray text-center sm:text-left mb-2 sm:mb-0 sm:w-40 sm:mt-1">
                Trending Searches
            </h3>
            <div className="flex flex-wrap gap-2 sm:gap-3 capitalize justify-center sm:justify-start">
                {queryData?.data?.map((link, index) => (
                    <p
                        key={index}
                        className="bg-[#F3F3F6] p-1 rounded-full px-2 text-[10px] sm:text-xs shadow-md sm:shadow-none cursor-pointer"
                        onClick={() => setSearchKey(link)}
                    >
                        {link}
                    </p>
                ))}
            </div>
        </div>
    );
};

export default TrendingSearches;
