import React from "react";
import { useNavigate } from "react-router-dom";
import logos from "../../../constant/logo";

const TypeOfWorkOffer = () => {
    return (
        <section className="w-full px-5 sm:px-6 lg:px-8 py-0 md:py-12">
            <div className="max-w-7xl mx-auto flex flex-col gap-6 md:gap-10">
                <h1 className="text-lg sm:text-3xl md:text-4xl font-semibold uppercase text-center">
                    Type of work portfolio offers
                </h1>

                <div className="columns-2 sm:columns-4 space-y-5">
                    <Card
                        img={logos.Sofa}
                        title={"Interior Design"}
                        className="!w-80 !h-[150px] sm:!h-[220px] !rounded-lg"
                    />
                    <Card
                        img={logos.WebDevelopment}
                        title={"Web Development"}
                        className="!h-40 sm:!h-64 !w-80 !rounded-lg"
                    />
                    <Card
                        img={logos.GraphicDesign}
                        title={"Graphics Design"}
                        className="!h-40 sm:!h-64 !w-80 !rounded-lg"
                    />

                    <Card
                        img={logos.BookImage}
                        title={"Cover Design"}
                        className={"!rounded-lg !h-80 !w-80"}
                    />
                    <Card
                        img={logos.Planning}
                        title={"Architecture"}
                        className={"!rounded-lg"}
                    />
                    <Card
                        img={logos.Letters}
                        title={"Logo Design"}
                        className={"!rounded-lg"}
                    />

                    <Card
                        img={logos.VideoEditing}
                        title={"Video Editing"}
                        className={"!rounded-lg"}
                    />
                    <Card
                        img={logos.Youtube}
                        title={"Product Design"}
                        className="!rounded-lg !h-80 !object-cover"
                    />

                    <Card
                        img={logos.Branding}
                        title={"Branding"}
                        className="!rounded-lg sm:!h-[365px] !w-full"
                    />
                    <Card
                        img={logos.ManStanding}
                        title={"VFX & Media"}
                        className={"!rounded-lg !h-64 sm:!h-full"}
                    />
                </div>
            </div>
        </section>
    );
};

const Card = ({ img, title, className }) => {
    const navigate = useNavigate();

    return (
        <div
            className={`relative group scale-animation `}
            onClick={() => navigate(`/gigs?search=${title}`)}
        >
            <img
                src={img}
                alt=""
                className={`cursor-pointer group-hover:opacity-30 ${className} `}
                loading="lazy"
            />
            <p className="text-animation !text-black text-center text-xs">
                {title}
            </p>
        </div>
    );
};

export default TypeOfWorkOffer;
