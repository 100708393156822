import React from "react";
import { useNavigate } from "react-router-dom";
import logos from "../../../constant/logo";

const Banner = () => {
    const navigate = useNavigate();

    return (
        <div className="w-full h-80 center-vertical max-w-[340px] sm:max-w-7xl mx-auto p-8 gradient-blue rounded-lg relative overflow-hidden">
            {/* Main content */}
            <div className="relative z-10 text-center max-w-2xl mx-auto">
                <h1 className="text-white text-lg md:text-3xl font-bold mb-6">
                    LEARN HOW WE CONNECT THE RIGHT PEOPLE
                </h1>

                <button
                    className="text-xs sm:text-sm bg-white text-blue-600 px-8 py-2 rounded-md font-medium hover:bg-blue-50 transition-colors duration-200"
                    onClick={() => navigate("/jobs")}
                >
                    EXPLORE
                </button>
            </div>

            <img
                src={logos.Vector}
                alt=""
                className="absolute bottom-0 left-32 opacity-50"
                loading="lazy"
            />
            <img
                src={logos.Vector2}
                alt=""
                className="absolute top-0 left-0 size-40 opacity-50"
                loading="lazy"
            />
            <img
                src={logos.Vector3}
                alt=""
                className="absolute bottom-0 right-10 size-40 opacity-50"
                loading="lazy"
            />
        </div>
    );
};

export default Banner;
