import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../common/Loader/Loader";
import {
    useGetMyProfile,
    useUpdateMyProfile,
} from "../hooks/Authentication/AuthenticationHooks";
import useGetAccessToken from "../hooks/Authentication/useGetAccessToken";
import { getDataFromLocalStorage } from "../hooks/useLocalStorage";
import { profileActions } from "../store/profile.slice";

const notIncludedFields = [
    "email",
    "firstname",
    "lastname",
    "password",
    "confirmPassword",
];

const GoogleVerification = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();
    const accessToken = searchParams.get("accessToken");
    const email = searchParams.get("email");

    const { setCookie, cookie } = useGetAccessToken();

    const localData = getDataFromLocalStorage({ key: "userRegisterData" });

    const filteredData = Object.fromEntries(
        Object.entries(localData).filter(
            ([key]) => !notIncludedFields.includes(key)
        )
    );

    const { runMutation, isMutationSucceeded } = useUpdateMyProfile({
        data: {
            ...filteredData,
            role: [filteredData?.role],
            email,
        },
    });

    const { queryData: myProfile } = useGetMyProfile();

    useEffect(() => {
        setCookie("accessToken", accessToken, { path: "/" });
        if (cookie.accessToken) {
            runMutation();
        }
    }, [accessToken, cookie.accessToken, runMutation, setCookie]);

    useEffect(() => {
        if (isMutationSucceeded) {
            dispatch(profileActions.setProfileDetails(myProfile?.data));
            navigate("/");
        }
    }, [isMutationSucceeded, navigate, myProfile?.data, dispatch]);

    return <Loader />;
};

export default GoogleVerification;
