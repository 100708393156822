
const logos = {
    Credit: "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/Listing/Credit.png",
    google: "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/GoogleLogo.png",
    largeLogo:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LargLogo.png",
    NoPostFoundImage:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/NoPostFound.png",
    ResumeLogo:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/Resume.png",
    CertificateImage:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/Certificate.png",
    LockGif:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/lock.png",
    DotIcon:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/Dot.svg",
    Logo: "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/Logo+1.svg",
    LogoWhite:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LogoWhite.svg",
    Moon: "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/moon.svg",
    Sun: "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/sun.svg",
    NoContent:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/NoContent.svg",
    UpworkBadge:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/UpworksBadge.png",
    HeroBg: "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/Listing/HeroBg.png",
    BookImage:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/Book.jpeg",
    Branding:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/Branding.jpg",
    FeatureSectionBgCircles:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/FeatureSectionBgCircles.svg",
    FeatureSectionMain:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/FeatureSectionMainImage.png",
    GraphicDesign:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/Graphics_Design.jpg",
    LeftEyebrow:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/LeftEyebrow.png",
    LeftHero:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/LeftHeroImage.svg",
    Letters:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/Letters.jpeg",
    LogoDesign:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/LogoDesign.png",
    ManStanding:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/Man.jpeg",
    MotionGraphics:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/MotionGraphics.jpg",
    Planning:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/Planning.jpeg",
    Possibilities1:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/Possibilities1.png",
    Possibilities1_1:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/Possibilities1_1.png",
    Possibilities2:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/Possibilities2.png",
    Possibilities3:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/Possibilities3.png",
    Possibilities4:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/Possibilities4.png",
    Possibilities5:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/Possibilities5.png",
    Possibilities6:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/Possibilities6.png",
    RightEyebrow:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/RightEyebrow.png",
    RightHero:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/RightHeroImage.svg",
    SocialMedia:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/SocialMedia.png",
    Sofa: "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/Sofa.jpeg",
    Testimonial:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/Testimonial.png",
    TypeOfWorkOffer:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/TypeOfWorkOffer.png",
    Vector: "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/Vector.png",
    Vector2:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/Vector2.png",
    Vector3:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/Vector3.png",
    VideoEditing:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/VideoEditing.jpeg",
    VoiceOver:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/VoiceOver.png",
    WebDevelopment:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/Web_Development.jpg",
    WorkOffer1:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/WorkOffer1.png",
    WorkOffer2:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/WorkOffer2.png",
    WorkOffer3:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/WorkOffer3.png",
    WorkOffer4:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/WorkOffer4.png",
    WorkOffer5:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/WorkOffer5.png",
    WorkOffer6:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/WorkOffer6.png",
    WorkOffer7:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/WorkOffer7.png",
    Youtube:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/Youtube.jpeg",
    Briefcase:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/LandingPage/Briefcase.svg",
    Employment:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/FreelancerProfile/Employment.svg",
    FreelancerTestimonial:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/FreelancerProfile/Testimonial.svg",
    Trophy: "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/FreelancerProfile/Trophy.svg",
    FooterCarousal1:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/Footer/Carousal1.png",
    FooterCarousal2:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/Footer/Carousal2.png",
    FooterCarousal3:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/Footer/Carousal3.png",
    FooterCarousal4:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/Footer/Carousal4.png",
    FooterCarousal5:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/Footer/Carousal5.png",
    FooterCarousal6:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/Footer/Carousal6.png",
    FooterCarousal7:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/Footer/Carousal7.png",
    FooterCarousal8:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/Footer/Carousal8.png",
    FooterFB:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/Footer/FB.png",
    FooterInstagram:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/Footer/instagram.png",
    FooterLinkedin:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/Footer/linkedin.png",
    FooterPinterest:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/Footer/Pinterest.png",
    FooterTwitter:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/Footer/Twitter.png",
    ComingSoonBlur:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/ComingSoon/Blur.png",
    ComingSoonConfettiBadge:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/ComingSoon/Confetti_Badge.png",
    ComingSoonConfettiCircle:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/ComingSoon/Confetti_Circle.png",
    ComingSoonConfettiDot:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/ComingSoon/Confetti_Dot.png",
    ComingSoonConfettiRibbon:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/ComingSoon/Confetti_Ribbon.png",
    ComingSoonConfettiSquare:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/ComingSoon/Confetti_Square.png",
    ComingSoonPolygon:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/ComingSoon/Polygon.png",
    AnimationCoin:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/Animation/Coin.gif",
    AnimationLock:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/Animation/Lock.gif",
    AnimationSentRequest:
        "https://dev-portfolio-user-profile-imgs.s3.ap-south-1.amazonaws.com/assets/Animation/Sent_Request.gif",
};

export default logos;
