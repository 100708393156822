import React from "react";
import logos from "../../../constant/logo";

const Services = () => {
    return (
        <div className="md:mt-20 w-full max-w-7xl mx-auto px-5 sm:px-8">
            {/* Section Heading */}
            <h3 className="text-primary-purple text-xs sm:text-sm uppercase font-semibold text-start">
                Services
            </h3>

            <div className="flex flex-col lg:flex-row lg:justify-between gap-12 mt-4 sm:mt-6">
                {/* Text Content */}
                <div className="text-start max-w-xl">
                    <p className="text-lg md:text-3xl lg:text-4xl font-semibold uppercase leading-snug">
                        ALL THE SERVICES YOU NEED AND{" "}
                        <span className="blue-gradient-text">MORE</span>{" "}
                    </p>
                    <p className="pt-4 md:pt-6 text-sm md:text-base text-gray-600 leading-relaxed">
                        Search confidently in over 700 categories for the new
                        talent, and gigs for freelancers. Our site has only one
                        goal—getting things done fast, without complications,
                        and within your budget.
                    </p>
                </div>

                {/* Services Icons Grid */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 w-full lg:max-w-2xl">
                    <ServicesIcon
                        img={logos.SocialMedia}
                        bgColor="bg-[#F682A5]"
                        serviceName="Social Media"
                    />
                    <ServicesIcon
                        img={logos.LogoDesign}
                        bgColor="bg-[#FEDF6F]"
                        serviceName="Logo Design"
                    />
                    <ServicesIcon
                        img={logos.VoiceOver}
                        bgColor="bg-[#F9A474]"
                        serviceName="Voice Over"
                    />
                </div>
            </div>
        </div>
    );
};

const ServicesIcon = ({ img, bgColor, serviceName }) => {
    return (
        <div className={`${bgColor} p-3 md:p-4 rounded-lg`}>
            <img
                src={img}
                alt={serviceName}
                className="w-full h-32 sm:h-36 md:h-48 object-cover rounded-lg transition-transform hover:scale-105 duration-300 ease-in-out"
                loading="lazy"
            />
            <p className="pt-2 md:pt-6 text-start font-bold text-xs md:text-sm uppercase">
                {serviceName}
            </p>
        </div>
    );
};

export default Services;
