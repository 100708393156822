import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Divider,
    Image,
} from "@nextui-org/react";
import { useQueryClient } from "@tanstack/react-query";
import { Location } from "iconsax-react";
import { Bookmark } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../../common/components/CommonButton";
import ExpandableDiv from "../../../common/components/ExpandableDiv";
import InsufficientCreditsModal from "../../../common/components/InsufficientCreditsModal";
import LockedFeature from "../../../common/components/LockedFeature";
import OverlayFragment from "../../../common/components/OverlayFragment";
import SideDrawer from "../../../common/components/SideDrawer";
import {
    formatDate,
    GenerateProfileImg,
    GetCreditRequired,
    handleProfileRedirection,
    HaveSufficientCredits,
    isApplicableToApply,
} from "../../../common/ReusableFunctions";
import { CREDIT_ACTION, CreditActionTypes } from "../../../constant/constants";
import logos from "../../../constant/logo";
import { useUpdateMyProfile } from "../../../hooks/Authentication/AuthenticationHooks";
import useGlobalMutation from "../../../hooks/useGlobalMutation";
import { ROUTES } from "../../../Routes";
import GigOverview from "./GigOverview";

export default function GigCard({ data, type }) {
    const { profileDetails } = useSelector((state) => state.profile);
    const { creditActionData } = useSelector((state) => state.custom);

    const [drawerOpen, setIsDrawerOpen] = useState(false);
    const [savedGigsIds, setSavedGigsIds] = useState();
    const [showInterestModalOpen, setShowInterestModalOpen] = useState(false);
    const [showInsufficientCoinsModalOpen, setShowInsufficientCoinsModalOpen] =
        useState(false);

    const navigate = useNavigate();

    const { runMutation } = useUpdateMyProfile({});
    const queryClient = useQueryClient();

    const {
        runMutation: revealContact,
        mutationLoading: revealingContact,
        mutationError: revealContactError,
        isMutationSucceeded: revealContactIsSucceeded,
    } = useGlobalMutation({
        url: ROUTES.SUBSCRIPTION.REVEAL_CONTACT,
        methodType: "POST",
        data: {
            id: data?.userDetails?._id,
            jobId: data?._id,
            type: CreditActionTypes["Apply Opportunity"],
        },
        closePopup: setShowInterestModalOpen,
    });

    const isAlreadyApplied = useMemo(() => {
        if (type === "gigs") {
            return profileDetails?.gigsApplied?.includes(data?._id);
        } else {
            return profileDetails?.jobsApplied?.includes(data?._id);
        }
    }, [profileDetails, data, type]);

    const isSaved = useMemo(() => {
        return savedGigsIds?.includes(data?._id);
    }, [savedGigsIds, data?._id]);

    const handleSavePost = (postId) => {
        const dataToUpload =
            type === "gigs"
                ? {
                      savedGigs: [
                          ...(Array.isArray(savedGigsIds) ? savedGigsIds : []),
                          postId,
                      ],
                  }
                : {
                      savedJobs: [
                          ...(Array.isArray(savedGigsIds) ? savedGigsIds : []),
                          postId,
                      ],
                  };

        runMutation({
            isPriorityDataAvailable: true,
            priorityData: dataToUpload,
        });

        queryClient.invalidateQueries({ queryKey: [`all-${type}`] });

        setSavedGigsIds((prevIds) => {
            if (prevIds && !prevIds?.includes(postId)) {
                return [...prevIds, postId];
            }
            return prevIds;
        });
    };

    const handleUnSavePost = (postId) => {
        const dataToUpload =
            type === "gigs"
                ? {
                      savedGigs: savedGigsIds?.filter((id) => id !== postId),
                  }
                : {
                      savedJobs: savedGigsIds?.filter((id) => id !== postId),
                  };

        runMutation({
            isPriorityDataAvailable: true,
            priorityData: dataToUpload,
        });

        queryClient.invalidateQueries({ queryKey: [`all-${type}`] });

        setSavedGigsIds((prevIds) => {
            if (prevIds?.includes(postId)) {
                return prevIds.filter((id) => id !== postId);
            }
            return prevIds;
        });
    };

    useEffect(() => {
        if (type === "gigs") {
            setSavedGigsIds(profileDetails?.savedGigs);
        } else {
            setSavedGigsIds(profileDetails?.savedJobs);
        }
    }, [profileDetails, type]);

    // useEffect(() => {
    //     !revealContactIsSucceeded && setShowInsufficientCoinsModalOpen(true);
    // }, [revealContactIsSucceeded]);

    const isContactIsRevealed = useMemo(() => {
        let isEmailExits =
            data?.userDetails?.email &&
            (data?.userDetails?.email !== null ||
                data?.userDetails?.email !== "");
        let isPhoneExits =
            data?.userDetails?.phoneNumber &&
            (data?.userDetails?.phoneNumber !== null ||
                data?.userDetails?.phoneNumber !== "");
        return isEmailExits || isPhoneExits ? true : false;
    }, [data]);

    const creditRequired = useMemo(() => {
        return GetCreditRequired({
            actionName: CREDIT_ACTION.POST_JOB,
            amount: data?.jobCostEstimate,
            creditActionData,
        });
    }, [data, creditActionData]);

    const haveSufficientCreditsToRevealContact = HaveSufficientCredits({
        actionName: CREDIT_ACTION.SHOW_CONTACT,
    });

    return (
        <Card
            className="w-full"
            classNames={{
                base: "hover:shadow-md shadow-none border",
            }}
        >
            <CardHeader className="flex sm:items-start justify-between gap-3">
                <div className="flex justify-between w-full">
                    <div className="flex flex-row  gap-3">
                        <Image
                            alt="Profile"
                            height={40}
                            radius="full"
                            src={
                                data?.userDetails?.imgUrl ??
                                GenerateProfileImg({
                                    firstname: data?.userDetails?.firstname,
                                    lastname: data?.userDetails?.lastname,
                                    imgUrl: data?.userDetails?.imgUrl,
                                }) ??
                                "https://avatars.githubusercontent.com/u/86160567?s=200&v=4"
                            }
                            width={40}
                            onClick={() =>
                                handleProfileRedirection({
                                    role: data?.userDetails?.role?.[0],
                                    userId: data?.userDetails?._id,
                                    navigate,
                                })
                            }
                            className="cursor-pointer"
                        />
                        <div className="flex flex-col items-start sm:items-start">
                            <p
                                className="text-sm sm:text-base text-center sm:text-left cursor-pointer"
                                onClick={() =>
                                    handleProfileRedirection({
                                        role: data?.userDetails?.role?.[0],
                                        userId: data?.userDetails?._id,
                                        navigate,
                                    })
                                }
                            >
                                {data?.userDetails?.firstname}{" "}
                                {data?.userDetails?.lastname}
                            </p>
                            <p className="flex items-center gap-1 text-xs sm:text-small text-default-500">
                                <Location className="size-4" />
                                {data?.userDetails?.location ?? "Anywhere"}
                            </p>
                        </div>
                    </div>

                    {isApplicableToApply({
                        type,
                        role: profileDetails?.role?.[0],
                    }) && (
                        <div className="flex items-center gap-3">
                            <button
                                className="hover:bg-gray-100 p-1.5 rounded-full border"
                                onClick={() => runMutation()}
                            >
                                <Bookmark
                                    size={20}
                                    className={`w-5 h-5 text-gray-500 ${
                                        isSaved && "fill-blue-500"
                                    }`}
                                    onClick={() => {
                                        isSaved
                                            ? handleUnSavePost(data?._id)
                                            : handleSavePost(data?._id);
                                    }}
                                />
                            </button>
                            <CommonButton
                                title={
                                    isContactIsRevealed
                                        ? "Shown Interest"
                                        : type === "gigs"
                                        ? "Show interest"
                                        : "Show interest"
                                }
                                isRounded
                                buttonStyle={
                                    "!w-auto !text-xs !text-blue-500 !border-blue-500"
                                }
                                handleFunction={() => {
                                    haveSufficientCreditsToRevealContact
                                        ? setShowInterestModalOpen(true)
                                        : setShowInsufficientCoinsModalOpen(
                                              true
                                          );
                                }}
                                disabled={isContactIsRevealed}
                            />
                        </div>
                    )}
                </div>
            </CardHeader>
            <Divider />
            <CardBody
                onClick={() => setIsDrawerOpen(true)}
                className="cursor-pointer"
            >
                <h1 className="text-xs sm:text-sm font-semibold text-start sm:text-left">
                    {data?.jobTitle}
                </h1>
                <div className="flex flex-col sm:flex-row sm:justify-between sm:flex-wrap gap-0 sm:gap-5 text-xs sm:text-sm text-[#676767] sm:font-medium mt-2">
                    <div className="flex flex-col sm:flex-row sm:gap-5">
                        <p>
                            Experience Level Needed: {data?.jobExperienceLevel}
                        </p>
                        <p>Max. Budget Allocated: {data?.jobCostEstimate}</p>
                        <p className="text-left">
                            Scope of work: {data?.jobScope}
                        </p>
                    </div>

                    {/* <p className="flex gap-2 justify-start">
                        Credit Required{" "}
                        <span className="flex items-center gap-1">
                            {creditRequired}{" "}
                            <img
                                src={CreditLogo}
                                alt="Credit"
                                className="w-4 h-4"
                            />
                        </span>
                    </p> */}
                </div>

                <ExpandableDiv
                    text={data?.jobDescription}
                    lineClampStyle={"line-clamp-3"}
                    componentStyle={"py-3"}
                />

                <div className="flex flex-wrap gap-2 sm:gap-5 w-full">
                    {data?.skillsRequired?.map((t, index) => (
                        <div
                            key={index}
                            className="bg-[#F3F3F6] text-xs p-1 px-2 rounded-full"
                        >
                            {t}
                        </div>
                    ))}
                </div>
            </CardBody>
            <Divider />
            <CardFooter className="flex justify-between">
                {/* <div className="text-xs flex items-center gap-2">
                    <TickCircle className="text-white fill-green-400" />
                    Payment Verified
                </div> */}
                <div className="text-xs flex items-center gap-1">
                    <img
                        src={logos.Credit}
                        alt="Credit"
                        className="w-4 h-4"
                        loading="lazy"
                    />
                    Credits Required - {creditRequired}
                </div>
                <div className="text-xs text-gray-500">
                    Created: {formatDate({ dateString: data?.createdAt })}
                </div>
            </CardFooter>

            <SideDrawer open={drawerOpen} setOpen={setIsDrawerOpen}>
                <GigOverview
                    jobId={data?._id}
                    type={type}
                    isAlreadyApplied={isAlreadyApplied}
                />
            </SideDrawer>

            <OverlayFragment
                children={
                    <LockedFeature
                        title={`Show Interest to Reveal Contact Details About The ${
                            type === "gigs" ? "Client" : "Recruiter"
                        }!`}
                        description={`Take the first step towards new opportunities! Show your interest in this ${type} to connect with recruiters, explore exciting roles, and advance your career.`}
                        submitButtonTitle="Show Interest"
                        setIsOpen={setShowInterestModalOpen}
                        handleFunction={revealContact}
                        error={revealContactError}
                    />
                }
                isOpen={showInterestModalOpen}
                setIsOpen={setShowInterestModalOpen}
            />

            <OverlayFragment
                isOpen={showInsufficientCoinsModalOpen}
                setIsOpen={setShowInsufficientCoinsModalOpen}
                children={
                    <InsufficientCreditsModal
                        setIsOpen={setShowInsufficientCoinsModalOpen}
                    />
                }
            />
        </Card>
    );
}
