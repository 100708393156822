import React from "react";
import { useNavigate } from "react-router-dom";
import logos from "../constant/logo";

const Logo = ({ className }) => {
    const navigate = useNavigate();

    return (
        <img
            src={logos.Logo}
            className={className}
            alt="Logo"
            onClick={() => {
                navigate("/");
            }}
            loading="lazy"
        />
    );
};

export default Logo;
