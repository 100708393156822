import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useGetAccessToken from "../../hooks/Authentication/useGetAccessToken";
import Loader from "../../common/Loader/Loader";

const GoogleLogin = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const accessToken = searchParams.get("accessToken");

    const { setCookie, cookie } = useGetAccessToken();

    useEffect(() => {
        setCookie("accessToken", accessToken, { path: "/" });
        navigate("/");
    }, [accessToken, cookie.accessToken, navigate, setCookie]);

    return <Loader />;
};

export default GoogleLogin;
