import React from "react";
import logos from "../../../constant/logo";

const Testimonials = () => {
    return (
        <section className="w-full px-5 sm:px-6 lg:px-8 py-2 md:py-12">
            <div className="flex flex-col justify-center items-center max-w-7xl mx-auto gap-8 md:gap-12">
                {/* Section Header */}
                <div className="space-y-2 md:space-y-3 text-center">
                    <h3 className="text-primary-purple text-xs md:text-base text-center uppercase font-semibold">
                        Testimonials
                    </h3>
                    <h1 className="text-lg sm:text-3xl md:text-4xl font-semibold uppercase">
                        What They're Saying About PortfoliOO
                        <span className="lowercase">o</span>
                    </h1>
                </div>

                {/* Testimonial Card */}
                <div className="bg-white transition-shadow duration-300">
                    <div className="flex flex-col md:flex-row py-6 md:py-8 px-6 md:px-12 gap-6 md:gap-10 items-center">
                        {/* Image Section */}
                        <div className="w-full md:w-1/3 flex-shrink-0">
                            <img
                                src={logos.Testimonial}
                                alt="Testimonial"
                                className="w-full md:w-80 h-80 md:h-auto object-cover rounded-xl shadow-md"
								loading="lazy"
                            />
                        </div>

                        {/* Text Section */}
                        <div className="flex flex-col space-y-4 md:space-y-6 flex-grow text-center md:text-left">
                            <p className="text-xs md:text-sm text-gray-600 font-medium">
                                Kayo Honi (DNP, PMHNP-BC), Co-Founder | Google
                            </p>
                            <blockquote className="text-sm md:text-lg text-gray-800 italic">
                                "It's extremely exciting that PortfoliOOo has
                                freelancers from all over the world — it
                                broadens the talent pool. One of the best things
                                about PortfoliOOo is that while we're sleeping,
                                someone's working."
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonials;
