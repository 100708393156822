import React, { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import EyeTracker from "../../common/animation/EyeTracker";
import useGetAccessToken from "../../hooks/Authentication/useGetAccessToken";
import FreelancerListing from "../FreelancerListing/components/FreelancerListing";
import StatisticsCard from "../FreelancerProfile/components/StatisticsCard";
import GigListing from "../GigListing/components/GigListing";
import MessageList from "../Home/components/MessageList";
import PostListing from "../Post/pages/PostListing";
import AllTalents from "../TalentListing/components/AllTalents";
import AddPost from "./components/AddPost";
import FilterList from "./components/FilterList";
import FreelancerLeftSidePanel from "./components/FreelancerLeftSidePanel";
import ProfileCard from "./components/ProfileCard";
import UsersFilterList from "./components/UsersFilterList";

export const MainPageContext = createContext({
    filters: {},
    setFilters: () => {},
});

const Page = ({ tabName }) => {
    const [filters, setFilters] = useState({});
    const { accessToken } = useParams();
    const { setCookie } = useGetAccessToken();
    const { profileDetails } = useSelector((state) => state.profile);

    useEffect(() => {
        if (accessToken) {
            setCookie("accessToken", accessToken, { path: "/" });
        }
    }, [accessToken, setCookie]);

    return (
        <MainPageContext.Provider
            value={{
                filters,
                setFilters,
            }}
        >
            {/* Main Content */}
            <div className="w-full flex flex-col sm:flex-row justify-between px-5 sm:px-10 py-5 gap-10 bg-slate-50">
                {/* Left Sidebar */}
                <div className="w-full md:w-[300px] space-y-5">
                    <ProfileCard isSticky={tabName === "home"} />
                    <div className="block md:hidden">
                        <StatisticsCard
                            userDetails={profileDetails}
                            isReadOnly={false}
                        />
                    </div>
                    <div className="hidden md:block sticky top-20">
                        {tabName === "home" && <FreelancerLeftSidePanel />}
                    </div>
                    {(tabName === "jobs" || tabName === "gigs") && (
                        <FilterList isSticky={true} />
                    )}
                    {(tabName === "freelancers" || tabName === "talents") && (
                        <UsersFilterList isSticky={true} />
                    )}
                </div>

                <div className="">
                    <EyeTracker />
                    <div className="space-y-3 sm:space-y-5 mt-5 sm:mt-10 w-full">
                        {tabName === "home" && <AddPost />}
                        {tabName === "jobs" || tabName === "gigs" ? (
                            <GigListing
                                type={tabName}
                                context={MainPageContext}
                                showFilter={true}
                            />
                        ) : tabName === "freelancers" ? (
                            <FreelancerListing
                                context={MainPageContext}
                                showFilter={true}
                            />
                        ) : tabName === "talents" ? (
                            <AllTalents
                                context={MainPageContext}
                                showFilter={true}
                            />
                        ) : (
                            <PostListing />
                        )}
                    </div>
                </div>

                {/* Right Sidebar */}
                <div className="w-[300px] space-y-5 hidden xl:block">
                    <StatisticsCard
                        isSticky
                        userDetails={profileDetails}
                        isReadOnly={false}
                    />
                    <MessageList />
                </div>
            </div>
        </MainPageContext.Provider>
    );
};

export default Page;
