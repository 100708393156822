import { PhotoIcon } from "@heroicons/react/20/solid";
import { Button } from "@nextui-org/react";
import { Location, Video } from "iconsax-react";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import ErrorMessage from "../../../common/components/ErrorMessage";
import { UploadedImageMapper } from "../../../common/components/FileUploader";
import FormTextarea from "../../../common/components/FormFields/FormTextarea";
import FormikStateMonitor from "../../../common/FormikStateMonitor";
import { UserProfileImageUrl } from "../../../common/ReusableFunctions";
import useGlobalMutation from "../../../hooks/useGlobalMutation";
import { ROUTES } from "../../../Routes";
import FormikFormGenerator from "../../../utils/FormikFormGenerator";
import { commonValidations } from "../../../utils/formValidations";
import useUploadFiles from "../../../hooks/useUploadFiles";

const AddSocialPost = ({
    setIsOpen,
    assets,
    setPostAssets,
    data,
    isUpdate,
}) => {
    const { profileDetails } = useSelector((state) => state.profile);
    const [isError, setIsError] = useState(false);
    const [commonError, setCommonError] = useState();
    const [imageAssets, setImageAssets] = useState(
        isUpdate ? data?.assetImages : assets ?? []
    );
    const [formData, setFormData] = useState();

    const inputImgRef = useRef();
    const inputVideoRef = useRef();

    const { uploadFiles, isUploading } = useUploadFiles();

    const {
        runMutation,
        mutationData,
        mutationError,
        mutationLoading,
        isMutationSucceeded,
    } = useGlobalMutation({
        url: ROUTES.POST.CREATE_POST,
        methodType: isUpdate ? "PUT" : "POST",
    });

    const validationSchema = Yup.object().shape({
        body: commonValidations({
            customErrorMessage:
                "Post content cannot be empty. Please write something to share.",
            maxLength: 5000,
        }).stringValidation,
    });

    useEffect(() => {
        if (isMutationSucceeded) {
            setIsOpen(false);
            setImageAssets([]);
            setPostAssets && setPostAssets([]);
        }
    }, [mutationData, setIsOpen, isMutationSucceeded, setPostAssets]);

    return (
        <div>
            <div className="flex items-center gap-2">
                <img
                    src={UserProfileImageUrl()}
                    alt="Profile"
                    className="size-10 rounded-full object-cover"
                />

                <div>
                    <h1>
                        {profileDetails?.firstname} {profileDetails?.lastname}{" "}
                    </h1>
                    <div className="flex items-center gap-1 text-gray-600">
                        <Location className="size-4" />
                        <span className="text-xs sm:text-sm">
                            {profileDetails?.location ?? "Anywhere"}
                        </span>
                    </div>
                </div>
            </div>

            <FormikFormGenerator
                initialValues={{
                    body: isUpdate ? data?.body ?? "" : "",
                }}
                validationSchema={validationSchema}
                formClassName={"my-5"}
                handleSubmitFunction={async () => {
                    const files = imageAssets?.filter(
                        (asset) => asset instanceof File
                    );
                    const stringFiles = imageAssets.filter(
                        (asset) => typeof asset === "string"
                    );

                    const uploadedFiles = await uploadFiles({
                        files: files,
                        email: profileDetails?.email,
                    });

                    if (!isUploading) {
                        const imageAssets = [
                            ...(uploadedFiles || []),
                            ...(stringFiles || []),
                        ].filter(Boolean);

                        runMutation({
                            isPriorityDataAvailable: true,
                            priorityData: {
                                ...formData,
                                assetImages: [...(imageAssets ?? [])],
                                id: data?._id,
                            },
                        });
                    }
                }}
            >
                <FormikStateMonitor onChange={setFormData} />

                <div>
                    <FormTextarea
                        name={"body"}
                        placeholder={"What do you want to talk about?"}
                    />
                </div>

                {imageAssets?.length > 0 && (
                    <div className="mt-5">
                        <UploadedImageMapper
                            imageAssets={imageAssets}
                            inputRef={inputImgRef}
                            setImageAssets={setImageAssets}
                            multiple={true}
                        />
                    </div>
                )}

                {/* {videoAssets?.length > 0 && (
                    <div className="mt-5">
                        <UploadedImageMapper
                            imageAssets={videoAssets}
                            inputRef={inputVideoRef}
                            setImageAssets={setVideoAssets}
                            multiple={true}
                        />
                    </div>
                )} */}

                {isError && (
                    <div className="mt-5">
                        <ErrorMessage message={commonError} />
                    </div>
                )}

                {mutationError && (
                    <div className="mt-5">
                        <ErrorMessage message={mutationError} />
                    </div>
                )}

                <div className="flex justify-between border-t bg-white gap-3 mt-5 p-2">
                    <div className="flex items-center gap-5">
                        <PhotoIcon
                            className="size-7 cursor-pointer"
                            onClick={() => inputImgRef.current.click()}
                        />
                        <Video
                            className="cursor-pointer"
                            onClick={() => inputVideoRef.current.click()}
                        />
                    </div>

                    <Button
                        color="primary"
                        size="sm"
                        className="gradient-blue rounded-full"
                        type="submit"
                        isLoading={mutationLoading}
                        isDisabled={mutationLoading}
                    >
                        Submit
                    </Button>
                </div>
            </FormikFormGenerator>

            <input
                type="file"
                ref={inputImgRef}
                hidden
                accept={`${".jpeg,.jpg,.png,.webp"}`}
                multiple={true}
                onChange={(e) => {
                    if (e.target.files) {
                        var fileSize = e.target.files[0].size;
                        if (fileSize > 5000000) {
                            e.target.value = "";
                            setIsError(true);
                            setCommonError(
                                "File size too large. Maximum allowed size is 5MB"
                            );
                            return;
                        }

                        setImageAssets &&
                            setImageAssets((prevImages) => [
                                ...(prevImages || []),
                                ...e.target.files,
                            ]);
                    }
                }}
            />

            <input
                type="file"
                ref={inputVideoRef}
                hidden
                accept={`${".mp4,.webm"}`}
                multiple={true}
                onChange={(e) => {
                    if (e.target.files) {
                        var fileSize = e.target.files[0].size;
                        if (fileSize > 20000000) {
                            e.target.value = "";
                            setIsError(true);
                            setCommonError(
                                "File size too large. Maximum allowed size is 20MB"
                            );
                            return;
                        }

                        setImageAssets &&
                            setImageAssets((prevImages) => [
                                ...(prevImages || []),
                                ...e.target.files,
                            ]);
                    }
                }}
            />
        </div>
    );
};

export default AddSocialPost;
