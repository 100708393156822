export const ROUTES = {
    AUTHENTICATION: {
        REGISTER: "/api/auth/register",
        VALIDATE_EMAIL: "/api/auth/validate-n-send-otp",
        LOGIN: "/api/auth/login",
        SEND_OTP: "/api/auth/send-otp",
        RESET_PASSWORD: "/api/auth/reset-password",
        MY_PROFILE: "/api/auth/profile/me",
        ALL_USERS: "/api/auth/all",
        PROFILE_COMPLETION: "/api/auth/profile/completion",
    },
    LOGS: {
        CREATE: "/api/log",
    },
    PROJECT: {
        CREATE: "/api/project",
        GET_MY_PROJECTS: "/api/project",
        GET_ALL_PROJECTS: "/api/project/all",
    },
    JOB: {
        CREATE: "/api/job",
        GET_ALL_JOBS: "/api/job/all",
    },
    JOB_APPLICATION: {
        APPLY_JOB: "/api/application",
        UPDATE_APPLICATION: "/api/application",
        ALL_APPLICATIONS: "/api/application/all",
    },
    POST: {
        CREATE_POST: "/api/post",
        GET_ALL_POST: "/api/post/all",
        LIKE_POST: "/api/post/like",
        COMMENT_ON_POST: "/api/post/comment",
        GET_ALL_COMMENTS_OF_POST: "/api/post/comment/all",
        DELETE_COMMENT: "/api/post/comment",
    },
    MASTER: "/api/master",
    INVITE: {
        CRUD: "/api/approval",
        ALL: "/api/approval/all",
    },
    RESUME: {
        CRUD: "/api/resume",
        ALL: "/api/resume/all",
    },
    PAYMENT: {
        CHECKOUT: "/api/payment/checkout",
        VERIFY: "/api/payment/verify",
        STATUS: "/api/payment/status",
    },
    NOTIFICATION: {
        MY: "/api/notification/my",
        READ: "/api/notification/read",
        READ_ALL: "/api/notification/read/all",
    },
    CHAT: {
        ALL_CHATS: "/api/chat/all",
        CHAT_HISTORY: "/api/chat/history",
        ALL_LIVE_PEOPLE: "/api/chat/live-people/all",
        START_NEW_CHAT: "/api/chat/start-chat"
    },
    CERTIFICATE: {
        CRUD: "/api/certificate",
        ALL: "/api/certificate/all",
    },
    RATING: {
        CRUD: "/api/rating",
        ALL: "/api/rating/all",
    },
    SUBSCRIPTION: {
        CRUD: "/api/subscription",
        REVEALED_CONTACTS: "/api/subscription/revealed-contacts",
        REVEAL_CONTACT: "/api/contact",
        CREDIT_ACTION: {
            CRUD: "/api/credit-action",
            ALL: "/api/credit-action/all",
        }
    },
    FEATURE: {
        TRENDING_SEARCH: "/api/trending-search",
        GENERATE_PRESIGNED_URL: "/api/feature/generate-presigned-url",
    },
    DASHBOARD: {
        GET_DASHBOARD_DATA: "/api/dashboard/all",
    },
    REQUEST: {
        REQUEST_ACCESS: "/api/invite/request",
        GET_ALL: "/api/invite"
    }
};
