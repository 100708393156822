import React from "react";
import logos from "../../constant/logo";

const CreditLogo = () => {
	return (
		<div>
			<img src={logos.Credit} alt="Credit" loading="lazy" />
		</div>
	);
};

export default CreditLogo;
