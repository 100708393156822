import { useState, useEffect } from "react";
import FormikFormGenerator from "../../../utils/FormikFormGenerator";
import FileUploader from "../../../common/components/FileUploader";
import FormInputField from "../../../common/components/FormInputField";
import FormTextarea from "../../../common/components/FormFields/FormTextarea";
import { Button } from "@nextui-org/react";
import useGlobalMutation from "../../../hooks/useGlobalMutation";
import { ROUTES } from "../../../Routes";
import FormikStateMonitor from "../../../common/FormikStateMonitor";
import * as Yup from "yup";
import { commonValidations } from "../../../utils/formValidations";
import ErrorMessage from "../../../common/components/ErrorMessage";
import useUploadFiles from "../../../hooks/useUploadFiles";
import { useSelector } from "react-redux";

const AddArticle = ({ setIsOpen, assets, data, isUpdate }) => {
	const { profileDetails } = useSelector((state) => state.profile);

    const [banner, setBanner] = useState(isUpdate ? data?.banner : assets);
    const [formData, setFormData] = useState();

    const { uploadFiles, isUploading } = useUploadFiles();

    const { runMutation, mutationError, mutationLoading, isMutationSucceeded } =
        useGlobalMutation({
            url: ROUTES.POST.CREATE_POST,
            methodType: isUpdate ? "PUT" : "POST",
        });

    const validationSchema = Yup.object().shape({
        body: commonValidations({
            customErrorMessage:
                "Article content cannot be empty. Please write something to share.",
            maxLength: 5000,
        }).stringValidation,
        title: commonValidations({
            customErrorMessage: "Article title cannot be empty",
        }).stringValidation,
    });

    useEffect(() => {
        isMutationSucceeded && setIsOpen(false);
    }, [isMutationSucceeded, setIsOpen]);

    return (
        <FormikFormGenerator
            initialValues={{
                title: isUpdate ? data?.title : "",
                body: isUpdate ? data?.body : "",
            }}
            formClassName={"space-y-5"}
            validationSchema={validationSchema}
            handleSubmitFunction={async () => {
                const files = banner?.filter(
                    (asset) => asset instanceof File
                );
                const stringFiles = banner?.filter(
                    (asset) => typeof asset === "string"
                );

                const uploadedFiles = await uploadFiles({
                    files: files,
                    email: profileDetails?.email,
                });

                if (!isUploading) {
                    const imageAssets = [
                        ...(uploadedFiles || []),
                        ...(stringFiles || []),
                    ].filter(Boolean);

                    runMutation({
                        isPriorityDataAvailable: true,
                        priorityData: {
                            ...formData,
                            banner: imageAssets.length > 0 ? imageAssets : [],
                            type: "ARTICLES",
                            id: data?._id,
                        },
                    });
                }
            }}
        >
            <FormikStateMonitor onChange={setFormData} />

            <div>
                <p className="input-label mb-2">Article Banner</p>
                <FileUploader
                    imageAssets={banner}
                    setImageAssets={setBanner}
                    isImageOnly
                    multiple={false}
                />
            </div>

            <FormInputField
                name={"title"}
                placeholder={"Title"}
                label={"Article Title"}
            />

            <div>
                <p className="input-label mb-2">Article Body</p>
                <FormTextarea
                    name={"body"}
                    placeholder={"Write Something Special Today"}
                />
            </div>

            {mutationError && <ErrorMessage message={mutationError} />}

            <div className="flex justify-end">
                <Button
                    color="primary"
                    size="sm"
                    className="gradient-blue rounded-full"
                    type="submit"
                    isLoading={mutationLoading}
                    isDisabled={mutationLoading}
                >
                    Submit
                </Button>
            </div>
        </FormikFormGenerator>
    );
};

export default AddArticle;
