import React from "react";
import InfiniteMovingCards from "../../common/animation/InfiniteMovingCards";
import logos from "../../constant/logo";
import Banner from "./components/Banner";
import FAQ from "./components/FAQ";
import Features from "./components/Features/Features";
import Hero from "./components/Hero";
import Possibilities from "./components/Possibilities";
import Services from "./components/Services";
import Testimonials from "./components/Testimonials";
import TypeOfWorkOffer from "./components/TypeOfWorkOffer";

const footerCarousalList = [
    {
        title: "Illustration",
        img: logos.FooterCarousal1,
    },
    {
        title: "Branding",
        img: logos.FooterCarousal2,
    },
    {
        title: "Animation",
        img: logos.FooterCarousal3,
    },
    {
        title: "Product Design",
        img: logos.FooterCarousal4,
    },
    {
        title: "Mobile",
        img: logos.FooterCarousal5,
    },
    {
        title: "Web Design",
        img: logos.FooterCarousal6,
    },
    {
        title: "Print",
        img: logos.FooterCarousal7,
    },
    {
        title: "Typography",
        img: logos.FooterCarousal8,
    },
];

const Page = () => {
    return (
        <div className="">
            <div className="space-y-10 sm:space-y-16">
                {/* <AnimatedTitle /> */}
                <Hero />
                <Features />
                <Services />
                <Possibilities />
                <TypeOfWorkOffer />
                <Testimonials />
                <FAQ />
                <Banner />
                <div>
                    <InfiniteMovingCards
                        items={footerCarousalList}
                        direction="left"
                        speed="slow"
                    />
                </div>
            </div>
        </div>
    );
};

export default Page;
