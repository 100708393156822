import { AddCircle } from "iconsax-react";
import React, { useMemo } from "react";
import { AiOutlinePhone } from "react-icons/ai";
import { MdOutlineMail } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreditLogo from "../../../common/components/CreditLogo";
import ExpandableDiv from "../../../common/components/ExpandableDiv";
import Link from "../../../common/components/Link";
import Loader from "../../../common/Loader/Loader";
import {
    GenerateProfileImg,
    GetCreditRequired,
    isApplicableToApply,
} from "../../../common/ReusableFunctions";
import {
    CREDIT_ACTION,
    CreditActionTypes,
    ROLES,
} from "../../../constant/constants";
import useGlobalQuery from "../../../hooks/useGlobalQuery";
import { ROUTES } from "../../../Routes";
import Spinner from "../../../common/components/Spinner";
import useGlobalMutation from "../../../hooks/useGlobalMutation";
import RevealContactButton from "../../../common/components/RevealContactButton";

const GigOverview = ({ jobId, type }) => {
    const { profileDetails } = useSelector((state) => state.profile);
    const { creditActionData } = useSelector((state) => state.custom);

    const { queryData, isLoading } = useGlobalQuery({
        url: ROUTES.JOB.GET_ALL_JOBS,
        methodType: "POST",
        data: { id: jobId, email: profileDetails?.email },
        queryKey: ["job", jobId],
    });

    const { runMutation, mutationLoading } = useGlobalMutation({
        url: ROUTES.SUBSCRIPTION.REVEAL_CONTACT,
        methodType: "POST",
        data: {
            id: profileDetails?._id,
            jobId,
            type: CreditActionTypes["Apply Opportunity"],
        },
    });

    const isAlreadyApplied = useMemo(() => {
        if (type === "gigs") {
            return profileDetails?.gigsApplied?.includes(jobId);
        } else {
            return profileDetails?.jobsApplied?.includes(jobId);
        }
    }, [profileDetails, type, jobId]);

    const navigate = useNavigate();

    const data = useMemo(() => {
        return queryData?.data?.[0];
    }, [queryData]);

    const clientWebsite = useMemo(() => {
        return data?.userDetails?.webLinks?.filter(
            (link) => link?.type === "website"
        )?.[0]?.link;
    }, [data]);

    const creditRequired = useMemo(() => {
        return GetCreditRequired({
            actionName: CREDIT_ACTION.POST_JOB,
            amount: data?.jobCostEstimate,
            creditActionData,
        });
    }, [data, creditActionData]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="">
            <div className="flex flex-col lg:flex-row justify-between gap-5">
                {/* Main Content */}
                <div className="border-2 rounded-lg p-4 w-full">
                    {/* Header */}
                    <div className="flex items-start justify-between mb-6">
                        <div className="flex gap-4">
                            <img
                                src={GenerateProfileImg({
                                    imgUrl: data?.userDetails?.imgUrl,
                                    firstname: data?.userDetails?.firstname,
                                    lastname: data?.userDetails?.lastname,
                                })}
                                alt="Profile"
                                className="size-10 sm:size-12 bg-blue-500 rounded-full flex items-center justify-center text-white font-bold"
                            />
                            <div>
                                <h1 className="text-base sm:text-lg font-semibold">
                                    {data?.userDetails?.firstname}{" "}
                                    {data?.userDetails?.lastname}
                                </h1>
                                <p className="text-gray-500 text-xs sm:text-sm">
                                    @{" "}
                                    {data?.userDetails?.location ?? "Anywhere"}
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Job Description */}
                    <div className="mb-4 sm:mb-8">
                        <h2 className="text-sm sm:text-base font-semibold mb-2">
                            {data?.jobTitle}
                        </h2>

                        <ExpandableDiv
                            text={data?.jobDescription}
                            titleStyle={
                                "text-gray-600 mb-4 whitespace-pre-line text-xs sm:text-sm"
                            }
                            lineClampStyle={"line-clamp-6"}
                        />
                    </div>

                    {/* Requirements */}
                    <div className="mb-4 sm:mb-8">
                        <h2 className="text-sm sm:text-base font-semibold mb-2 sm:mb-4">
                            Requirements
                        </h2>
                        <div className="flex flex-col md:flex-row gap-2 sm:gap-4 mb-2 sm:mb-4">
                            <div className="flex items-center gap-2">
                                <span className="text-gray-600 text-xs sm:text-sm">
                                    Experience Level Needed:{" "}
                                    {data?.jobExperienceLevel}
                                </span>
                            </div>
                            <div className="flex items-center gap-2">
                                <span className="text-gray-600 text-xs sm:text-sm">
                                    Max. Budget Allocated:{" "}
                                    {data?.jobCostEstimate}
                                </span>
                            </div>
                            <div className="flex items-center gap-2">
                                <span className="text-gray-600 text-xs sm:text-sm">
                                    Scope of work: {data?.jobScope}
                                </span>
                            </div>
                        </div>
                        <div className="flex items-center gap-2 mb-6">
                            <span className="text-gray-600 flex items-center gap-5 text-xs sm:text-sm">
                                Credit required:{" "}
                                <span className="flex items-center gap-2">
                                    {creditRequired} <CreditLogo />
                                </span>
                            </span>
                        </div>
                    </div>

                    {/* Skills */}
                    <div className="sm:mb-8">
                        <h2 className="text-sm sm:text-base font-semibold mb-2 sm:mb-4">
                            Skills and experience
                        </h2>
                        <div className="flex flex-wrap gap-2">
                            {data?.skillsRequired?.map((skill, index) => (
                                <span
                                    key={index}
                                    className="px-4 py-2 bg-gray-100 rounded-full text-gray-700 text-xs sm:text-sm"
                                >
                                    {skill}
                                </span>
                            ))}
                        </div>
                    </div>

                    {data?.docUrls?.length > 0 && (
                        <div className="mb-4 sm:mb-8">
                            <h2 className="text-sm sm:text-base font-semibold mb-2 sm:mb-4">
                                Attachments
                            </h2>
                            <div className="flex flex-col gap-5">
                                {data?.docUrls?.map((url, index) => (
                                    <img key={index} src={url} alt="Documents" loading="lazy" className="w-full border rounded-xl" />
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                {/* Sidebar */}
                <div className="lg:w-80">
                    <div className="sticky top-0 space-y-4">
                        {isApplicableToApply({
                            type,
                            role: profileDetails?.role?.[0],
                        }) && (
                            <>
                                <button
                                    className="flex  items-center justify-center  gap-2 w-full gradient-blue text-white rounded-lg py-3 px-4 font-medium transition-colors disabled:opacity-50"
                                    onClick={() => navigate(`/apply/${jobId}`)}
                                    disabled={isAlreadyApplied}
                                >
                                    {isAlreadyApplied ? (
                                        "Already Applied"
                                    ) : (
                                        <div className="flex items-center gap-2">
                                            <AddCircle />
                                            Apply now
                                        </div>
                                    )}
                                </button>
                            </>
                        )}

                        {(profileDetails?.role?.[0] === ROLES.FREELANCER ||
                            profileDetails?.role?.[0] === ROLES.EMPLOYEE) && (
                            <RevealContactButton
                                creditActionType={
                                    CreditActionTypes["Apply Opportunity"]
                                }
                                data={data?.userDetails}
                                jobId={jobId}
                            />
                        )}

                        <div className="bg-white p-3  rounded-lg border border-gray-200">
                            <h2 className="font-semibold">About the client</h2>
                            <ExpandableDiv
                                text={data?.userDetails?.description}
                                componentStyle={"pt-2"}
                                lineClampStyle={"line-clamp-6"}
                            />

                            {clientWebsite && (
                                <div className="pt-5">
                                    <h3 className="font-semibold mb-1">
                                        Client website
                                    </h3>
                                    <Link
                                        link={clientWebsite}
                                        className={
                                            "text-blue-600 underline text-xs sm:text-sm"
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GigOverview;
